<template>
    <div>
        <div class="settings">
            <div class="settings__btn-wrapper">
                <button class="btn save" @click="saveFindings">Сохранить</button>
            </div>
            <div class="settings__block" v-for="(cat, name, index) in findings" :key="name">
                <h3 class="settings__block-title" @click="showItem">{{ category[index] }}</h3>
                <div class="settings__block-items">
                    <div class="settings__block-item" v-for="(catItem, index) in cat" :key="index">
                        <h5 class="settings__block-item-title" @click="showItem">{{ catItem.title }}</h5>
                        <div class="settings__block-item-table-wrapper">
                            <table class="settings__block-item-table">
                                <tbody>
                                    <tr v-for="(catItemResponses, index) in catItem.responses" :key="index">
                                        <td class="table-title">{{ catItemResponses.value }}</td>
                                        <td class="table-price">
                                            <input type="number" v-model="catItemResponses.price">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                category: ['Диван', 'Кресло', 'Стул', 'Кровать']
            }
        },
        mounted () {
            this.$store.dispatch('findings/loadFindings')
        },
        computed: {
            findings () {
                return this.$store.getters['findings/findings']
            },
        },
        methods: {
            saveFindings ()  {
                this.$store.dispatch('findings/updateFindings', this.findings)
            },
            showItem(e) {
                e.target.classList.toggle('active')
            }
        }
    }
</script>

<style lang="scss">
    .settings {
        width: 600px;
        margin: 0 auto;
        &__btn {
            &-wrapper {
                text-align: center;
                position: sticky;
                top: 100px;
                margin-bottom: 30px;
                z-index: 2;
            }
        }
        &__block {
            width: 100%;
            margin-bottom: 20px;
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            &:last-of-type {
                margin-bottom: 0;
            }
            &-title {
                position: relative;
                font-size: 24px;
                font-weight: 500;
                padding: 20px;
                background: #fbfbfb;
                user-select: none;
                cursor: pointer;
                &.active + .settings__block-items {
                    display: block;
                }
                &.active:before {
                    transform: translateY(-50%) rotate(180deg);
                }
                &:before {
                    content: '';
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 22px;
                    height: 12px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 12' width='22' height='12'%3E%3Cpath d='M21.71.29a1 1 0 00-1.42 0L11 9.59 1.71.29A1 1 0 00.29 1.71l10 10A1.05 1.05 0 0011 12a1 1 0 00.71-.29l10-10a1 1 0 000-1.42z' fill='%23737373'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
                    transition: .2s;
                }
            }
            &-items {
                display: none;
                padding: 20px;
                border-top: 1px solid #d3d3d3;
                -webkit-transition: height .3s ease;
                &.visible {
                    display: block;
                    -webkit-transition: height .3s ease;
                }
            }
            &-item {
                border-bottom: 1px solid #d3d3d3;
                padding-bottom: 10px;
                margin-bottom: 15px;
                &:last-of-type {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: none;
                }
                &-title {
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    cursor: pointer;
                    user-select: none;
                    &.active + .settings__block-item-table-wrapper {
                        display: block;
                    }
                }
                &-table {
                    width: 100%;
                    &-wrapper {
                        display: none;
                    }
                    & .table-title {
                        width: 100%;
                    }
                    & input {
                        width: auto;
                        text-align: right;
                        font-size: 16px;
                    }
                }
            }
        }
    }
</style>
